<template>
  <div class="add-building-type">
    <h1 class="admin-title">{{$t('Add Building Type')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent>
        <b-row>
          <b-col>
            <RFValidation name="Name" rules="required">
              <RFInput label="Name" v-model="buildingType.name"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

import { buildingTypeService } from '@/services/admin'

export default {
  name: 'AdminAddbuildingType',
  components: {
    RFValidation,
    RFInput,
    RFButton,
    RFLoader,
  },
  data() {
    return {
      buildingType: {
        name: null,
      },
      loading: false,
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        const { status } = await buildingTypeService.addBuildingType(this.buildingType)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Building type successfully added!`))
          this.$router.push({ name: 'building-type-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add building type`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>